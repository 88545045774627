.page.home {

	.container-wide {
		width: 100%;
		max-width: 100%;
		margin: 0;
		padding-left: 0;
		padding-right: 0;
	}
	#homepage-slider {
		overflow: hidden;
		height: calc(100vh - 133px);
		.slider {
			height: 100%;
			.slide {
				position: relative;
				.inner-content-wrap {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					// background-color: rgba(0,0,0,0.4);
				}
				.inner-content {
					text-align: center;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-content: center;
					
					height: 100%;

					// * { text-align: center; }
					h2 {
						font-size: 2em;
						line-height: 1.25;
						text-align: center;
						margin-bottom: 2rem;
					}
					color: $white;
					position: relative;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					.btn {
						display: inline-block;
						width: auto;
						background-color: $cpa_red;
						border-color: $cpa_red;
						padding: 0.8rem 4rem;
						font-size: 24px;
						font-weight: bold;
						&:hover {
							background-color: $cpa_red;
						}
					}
				}
			}

		}
	}

	.inner-content-wrap {
		height: 100%;
	}

	.section.entry-content {

			.main-content {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: center;
				width: 100%;
				padding: 1 * $grid-gutter-width;
				margin: auto;
				color: $gray-dark;
				h2 {
					color: $black;
				}
			}

			.image-box {
				display: block;
				position: relative;
				width: 100%;
				height: 100%;
				min-height: 50vh;
				
				//overflow:hidden;
				//padding-top: 100%;

				&.video {
					overflow:hidden;
					background: #000 !important;
					display: flex;
				}


				
				@include media-breakpoint-up(md) {

					&.cut:before {
						content: "";
						width: 100px;
						height: 100px;
						background: $white;
						position: absolute;
						top: 45%;
						left: -90px;
						transform: rotate(45deg);
					}
				}
			}

			.image-box1 {

				&.video1 {
					min-height: 60vh;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-content: center;
					height: 100%;
					width: 100%;
					// padding: 1.5em;

					 @include media-breakpoint-up(lg) {
						 min-height: 45vh;
					 }
				}

			}

	}

}

// #ytplayer {
//   position: relative;
//   left: 0;
//   top: 0;
//   margin: auto;
//   transform-origin: top left;
//   transform: scale(1);

//   z-index: 1;
 
//   @include media-breakpoint-up(md) {
//     transform: scale(1.6);
//     left: -30%;
//   }
//   @include media-breakpoint-up(lg) {
//     position: absolute;
//     transform: scale(1.67);
//     left: -20%;
//   }
//   @include media-breakpoint-up(xl){
//     left:0%;
//     transform: scale(1.6);
//   }
// }


@include media-breakpoint-up(md) {
	.page.home {
		#homepage-slider {
			height: calc(90vh - 104px);
			.slick-arrow {
				display: block;
			}
			.inner-content {
				position: relative;
				h2 {
					font-size: 3em !important;
				}
				&:after {
					display: none !important;
					content: "\f078";
					position: absolute;
					font-family: FontAwesome;
					font-size: 36px;
					left: 49%;
					bottom: 0;
					background-color: rgba(0,0,0,0.4);
					padding: 0 10px;
				}
			}
		}
	}
}

@include media-breakpoint-up(lg) {
	.page.home {

		.container-wide {
			width: 100%;
			max-width: 100%;
		}
	}
}
@include media-breakpoint-up(xl) {
	.page.home {

		.container-wide {
			width: 100%;
			max-width: 100%;
		}
	}
}

@include media-breakpoint-down(sm) {
	.slick-arrow {
		display: none !important;
	}
}