.pagination {

  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  margin: #{$grid-gutter-width * 2} auto;

  .pagination-link {
    display: inline-block;
    padding: 1em 2em;
    background-color: $gray-100;
    color: $black;
    line-height: 1;
    white-space: nowrap;
    font-weight: normal;
    &.current {
      font-weight: bold;
    }

    &:first-child {
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }
    &:last-child {
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
  }
  span.pagination-link {
    cursor: not-allowed;
    color: lighten($black, 4%);
  }
  a.pagination-link:hover {
    background-color: $cpa_blue;
    color: $white;
    text-decoration: none;
  }

}