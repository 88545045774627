.employment-list {

	.post.employment {
		display: block;
		position: relative;
		width: 100%;
		height: 100%;
		height: calc(100% - #{$grid-gutter-width});
		background-color: $gray-light;
		overflow: hidden;
		border-radius: $border-radius;
		margin-bottom: $grid-gutter-width;

		&:hover {
			background-color: #042C6A;
			color: #fff;
			& .inner-content {
				color: #fff !important;
			}
		}

		.info {
			display: block;
			width: 100%;
			height: 15rem;
			position: relative;

			.inner-content {
				display: block;
				position: absolute;
				top: 50%;
				left: 0;
				color: #000;
				transform: translateY(-50%);
				padding: $grid-gutter-width;
				.location {
					font-size: font-size(3);
				}
			}
		}

		footer {
			flex: 0;
			border-top: 1px solid $gray-500;
			.date {
				display: block;
				float: left;
				padding: .5rem;
				padding-left: 30px;

			}
			.more-btn {
				float: right;
				background-color: $cpa_red;
				color: $white;
				padding: .5rem 2rem;
				font-weight: bold;
				.fa {
					font-size: 12px;
				}
			}
		}
	}

}



body.single {
	article.type-employment {

		.single-employment {

			.info {
				background-color: $gray-light;
				border-radius: $border-radius;
				padding: 1em;
				margin-top: $grid-gutter-width;
				margin-bottom: $grid-gutter-width;
			}

		}

	}
}

.iframe-overlay {

	overflow: hidden;

}