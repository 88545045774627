header.site-header {
  .top {
    position: relative;
    background-color: $cpa_blue;
    color: $white;
    @include clearfix;
    .social-links {
      display: block;
      float: none;
      text-align: center;
    }
    .header-links {
      display: none;
    }


    nav.top-nav {
      display: block;
      float: none;
      font-size: 0.875rem;
      white-space: nowrap;
      a.top-link, a.social-link {
        display: inline-block;
        padding: 0.25em;
        color: $white;
        &:hover {
          color: $white;
        }
      }
      .top-link:not(:last-child):after {
        content: "|";
        margin-left: .5em;
      }
    }
  }

  .main {
    position: relative;
    background-color: $white;
    color: $cpa_blue;
    height: auto;

    .container {
      padding: ($grid-gutter-width / 2) 0;
      @include clearfix;
      text-align: center;
    }

    .custom-logo-link {
      display: block;

      padding: 0 (.5 * $grid-gutter-width);
      width: 70%;
      float: left;


    }
    .ubermenu-responsive-toggle {
      width: 30%;
      float: left;
      .fa {
        font-size: 3em;

      }
      &:after {
        content: "";
        display: table;
        clear: both;
      }
    }
    nav.ubermenu-main {
      width: 100%;
      display: block;
      position: relative;
    }
  }
}

header.page-header {
  width: 100%;
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $cpa_blue;
  margin-bottom: $grid-gutter-width * 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;

  .page-title {
    text-align: center;
    color: $white;
    margin: 0;
    z-index:9;
  }
}

header.page-header + .container,
header.page-header + article {
  margin-top: $grid-gutter-width;
}


@include media-breakpoint-up(md) {
  header.site-header {
    padding: 0;
    .top {
      display: block;
      float: none;
      .social-links {
        float: left;
        text-align: left;
        margin-right: $grid-gutter-width;
        font-size: .875rem;
        .social-link {}
      }
      .header-links {
        float: right;
        display: block;
      }
      nav.top-nav {
        display: block;
        float: right;
      }
    }
    .main {
      text-align: left;
      padding: 0;
      .container {
        display: flex;
        flex-direction: row;
        text-align: left;
        flex-wrap: wrap;
        padding: 0;
        .custom-logo-link {
          display: flex;
          align-content: center;
          flex: 0 0 100%;
          padding: 8px 0;
          align-content: center;
          justify-content: center;
          img {
            height: 60px;
          }
        }
        .ubermenu-responsive-toggle {
          // flex: 0 0 30%;
          position: absolute;
          right: 40px;
          .fa {
            float: right;
          }
        }

        nav.ubermenu {
          width: 100%;
          flex: 1 1 100%;
        }

      }
    }
  }
}

@include media-breakpoint-up(lg) {
  header.site-header {
    .main {
      .container {
        .custom-logo-link {
          flex: 0 0 208px;
        }
        nav.ubermenu {
          width: 100%;
          flex: 1;
        }
      }
    }
  }
}

.header-overlay30 {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,0.3);
}

/*** UBERMENU ***/

$ubermenu-hover-bg: #F6F6F6;
$ubermenu-hover-color: $cpa_blue;

.ubermenu.ubermenu-skin-minimal {
  -webkit-font-smoothing: antialiased;
  color: $cpa_blue;
  //height: 76px;
  //background-color: rgba(255,100,100,0.1);
  font-size: 1rem;
  font-weight: normal;
  .ubermenu-item-level-0 {

    > .ubermenu-target {
      height: 76px;
      font-weight: bold;
      color: $cpa_blue;
      vertical-align: middle;
      font-size: 20px;
      line-height: 1;
      padding-top: 28px;
      padding-bottom: 28px;

    }

    > ul.ubermenu-submenu-drop {
      border-top: 0;
      >li.ubermenu-column {
        .ubermenu-target-title, .ubermenu-target-text {
          color: $black;
        }
        >ul.ubermenu-submenu {
          >li.ubermenu-item-auto {
            >.ubermenu-target {
              //white-space: nowrap;

            }
          }
        }
      }
    }


  }
  .ubermenu-item-level-0 {
    &:hover, &:focus, &.uber-menu-active {
      background-color: $ubermenu-hover-bg;
      color: $ubermenu-hover-color;
      .ubermenu-target-text, .ubermenu-target-text {
        color: $ubermenu-hover-color;
      }
    }
  }
  .ubermenu-submenu {
    background-color: $ubermenu-hover-bg;
    .ubermenu-target-title, .ubermenu-target-text {
      color: $ubermenu-hover-color;
    }
  }
  .ubermenu-submenu-type-mega {
    margin-top: 0em;
    border: 0;
    border-radius: $border-radius;
  }

  .ubermenu-submenu-type-stack {
    .ubermenu-dynamic-post {
      .ubermenu-target {
        .ubermenu-target-title {
          margin-left: 0;
          &:before {
            content: ">";
            margin-right: .2em;
          }
        }
      }
    }
  }

}

//.ubermenu .ubermenu-submenu-id-372 {
//  width: auto;
//  min-width: 60vw;
//}

.ubermenu-submenu-align-center {
  left: 50% !important;
  transform: translateX(-50%);
  position: absolute;
}

.ubermenu-submenu {
  & .ubermenu-item-level-1 {
    //padding: 1rem;
  }

}

.ubermenu-sub-indicators {
  .ubermenu-has-submenu-drop>.ubermenu-target:after {
    right: 27px; 
    top: 46%; 
  }
}

.ubermenu-responsive-default.ubermenu-responsive .ubermenu-item-level-0 {
  width: 100%;
}

@include media-breakpoint-up(md) {
  .ubermenu-sub-indicators {
    .ubermenu-has-submenu-drop>.ubermenu-target:after {
      right: 7px; 
    }
  }

  .ubermenu-responsive-default.ubermenu-responsive .ubermenu-item-level-0 {
    width: auto;
  }
}

/* News Page - Header */
body.blog {
  header.page-header {
    height: 14vh;

    h1.page-title {
      @include media-breakpoint-up(lg) {
        font-size: 5rem;
      }
    }
  }
}

body.single-post {
  .banner {
    display: flex;
    align-items: center;
    background-color: $cpa_blue;
    min-height: 6vh;

    .h1, .h2 {
      color: $white;
    }
  }
  header.page-header {
    h4 {
      color: #868e96 !important;
      font-weight: 400 !important;
    }
  }
}

/* News Page - Header -- END */