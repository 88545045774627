.section {
  display: block;

  &.section--pad {
    padding-top: $grid-gutter-width * 2;
    padding-bottom: $grid-gutter-width * 2;
  }

  &.bg-palegray {
    background-color: $gray-light;
  }
}

@import 'sections/home-stats';
@import 'sections/home-toggle';
@import 'sections/latest-news';
@import 'sections/home-testimonials';
@import 'sections/faqs';
@import "sections/contact";