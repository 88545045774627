body.single-services, body.single-consulting {
  article.services {

    .entry-content {
      margin-top: $grid-gutter-width * 2;
      line-height: 1.8;
    }
    footer {
      padding-top: $grid-gutter-width;
      padding-bottom: $grid-gutter-width;

      .return-link {
        font-weight: bold;
        .fa {
          font-size: 0.875rem;
        }
      }
    }
  }
}


article.type-post {

  .entry-title {
    line-height: 1.25;
    margin-bottom: 1.2em;
  }
  .entry-content {
    p:not(:last-child) {
      margin-bottom: 1.4em;
      line-height: 1.8;
    }

    img {
      border-radius: $border-radius;
      max-width: 100%;
      height: auto;

    }

    margin-bottom: $grid-gutter-width;
  }

  footer {
    margin-bottom: $grid-gutter-width;

    a, a:link {
      .fa {
        font-size: 0.825em;
      }
      font-weight: bold;
    }
  }

}


article.post-summary, a.post-summary {
  max-width: 70%;
  min-height: 200px;
  // min-height: ( 8 * $grid-gutter-width );
  //height: calc(100% - #{$grid-gutter-width});
  border: 2px solid $gray-200;
  border-radius: $border-radius;
  padding: $grid-gutter-width;

  margin-bottom: $grid-gutter-width;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;


  //header {
  //  flex: 1;
  //  display: flex;
  //  flex-direction: column;
  //  align-items: flex-start;
  //  justify-content: space-between;
  //}
  time {
    color: $gray-600;
    font-size: font-size(1);
    display: block;
    min-height: 1em;
    margin-bottom: 1em;
    align-self: flex-start;
    justify-self: flex-start;
  }
  .title {
    display: block;
    text-align: left;
    color: #000;
  }

  .read-more {
    justify-self: flex-end;
    font-weight: normal;
    .fa {
      font-size: 0.825em;
    }
  }
  .entry-summary {
    display: none;
  }
  &:hover {
    background-color: $cpa_blue;
    color: $white;
    text-decoration: none;
    time, .title, .read-more {
      color: $white;
    }
  }

  &.feature {
    min-height: ( 20 * $grid-gutter-width );
    align-items: center;
    justify-content: space-around;
  }

}