$point-size: 25px;

.point {
  position: absolute;
  z-index: $zindex-fixed;
  width: 0;
  height: 0;
  display: none;

  &.point-left {
    border-top: $point-size solid transparent;
    border-bottom: $point-size solid transparent;
    border-left: $point-size solid #FFF;
    margin-top: (-1 * $point-size);
    top: 50%;
    left: 0;
    right: auto;
  }
  &.point-right {
    border-top: $point-size solid transparent;
    border-bottom: $point-size solid transparent;
    border-right: $point-size solid #FFF;
    margin-top: (-1 * $point-size);
    top: 50%;
    right: 0;
    left: auto;
  }
  &.point-bottom {
    border-left: $point-size solid transparent;
    border-right: $point-size solid transparent;
    border-top: $point-size solid #FFF;
    bottom: 0;
    left:50%;
    margin-left: (-1 * $point-size);
  }
}

@include media-breakpoint-up(md) {
  display: block;
}