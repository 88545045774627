.page-builder-block--cta-block {
    display: flex;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 120px 0;
    position: relative;

    @include media-breakpoint-up(lg) {
        padding: 120px 0;
        min-height: 381px;
    }

    &.has-overlay {
        &:after {
            display: block;
            content: '';
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: linear-gradient(0deg, rgba(6, 33, 74, 0.8), rgba(6, 33, 74, 0.8));
        }
    }

    &__content {
        position: relative;
        z-index: 2;
        h1, h2, h3, h4, h5, h6, p {
            color: $white;
        }
    }
}