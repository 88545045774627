.filter {
	font-size: 18px;
	font-weight: bold;
	margin: 0 auto;
	text-align: center;
	&-selector {
		padding-left: 0;
		line-height: 23px;
		list-style: none;
		display: inline-block;
	}
	&-item {
		font-weight: 400;
		display: inline-block;
		margin-right: 10px;

		&:first-of-type {
			margin-left: 10px;
		}
		
		
		&:after {
			content: "/";
			margin-left: 10px;
		}


		&:last-of-type {
		
			&:after {
				
				display: none;
			
			}
			
		}
		

	}
}

.mobile-only-link {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	display: block;
	 @include media-breakpoint-up(lg) {
		 display: none;
	 }
}

.team-member {
	.info {
		display: none;

		@include media-breakpoint-up(lg) {
			display: block;
		}
	}
}
.section-team {
	transition: 2s;
}

@keyframes shiftIn {
0% { opacity: 0;}
100% { opacity: 1;}
}

@keyframes shiftOut {
0% { opacity: 1;}
100% { opacity: 0;}
}
.team-list {

	.filter-selector-data {
		display: none;
		opacity: 0;
		animation: shiftOut 0.5s;
		&.active {
			opacity: 1;
			display: block !important;
			animation: shiftIn 1s;
		}
	}
	.team-member {
		height: 0;
		width: 100%;
		padding-top: 100%;
		border-radius: $border-radius;
		position: relative;
		overflow: hidden;

		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;

		>img {
			max-width: 100%;
			width: 100%;
		}

		.overlay {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			transform: translateY(100%);
			background-color: $cpa_blue;
			color: $white;
			text-align: center;
			padding: $grid-gutter-width * 0.5;
			transition: all 0.4s ease-in-out;

			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			.name {
				margin-bottom: 1em;
			}

			&:hover {
				text-decoration: none;
			}
			&:after {
				bottom: 0;
				left: 50%;
				border-bottom: $grid-gutter-width solid $white;
				border-left: $grid-gutter-width solid transparent;
				border-right: $grid-gutter-width solid transparent;
				content: "";
				position: absolute;
				margin-left: -1 * $grid-gutter-width;
			}
		}

		&:hover {
			.overlay {
				transform: translateY(0);
			}
		}

		.staff-social-links {
			list-style: none;
			padding: 0;

			&--item {
				display: inline-block;
				color: #fff;
				padding: .2rem .5rem;
				margin-right: 0.1rem;
			}
		}
	}
}

body.single-team {
	article.team {
		padding-top: 2 * $grid-gutter-width;
		padding-bottom: 2 * $grid-gutter-width;

		.entry-title {
			margin-bottom: .5em;
		}
		.position {
			margin-bottom: .5em;
		}
		.entry-content {
			line-height: 1.8;
		}
	}

	.back-to-link {
		padding-top: $grid-gutter-width;
		padding-bottom: $grid-gutter-width;
		a {
			font-weight: bold;
		}
	}


	.social-links {
		text-align: center;
		display: block;
		font-size: 1.5rem;

		a {
			display: inline-block;
			&:not(:last-child) {
				margin-right: 1em;
			}
		}
	}


	.single-team-member {
		.image {
			img {
				max-width: 100%;
				height: auto;
				margin-bottom: $grid-gutter-width;
			}
		}
	}
}



/** MD Breakpoint **/
@include media-breakpoint-up(md) {

	.team-list {
		.team-member {




			.info {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				transform: translateY(100%);
				background-color: $cpa_blue;
				color: $white;
				text-align: center;
				padding: $grid-gutter-width * 0.5;
				transition: all 0.4s ease-in-out;

				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				

				.name {
					margin-bottom: 1em; 
					color: #fff;
				}
				.position {
					color: #fff;
				}

				&:hover {
					text-decoration: none;
				}
				&:after {
					bottom: 0;
					left: 50%;
					border-bottom: $grid-gutter-width solid $white;
					border-left: $grid-gutter-width solid transparent;
					border-right: $grid-gutter-width solid transparent;
					content: "";
					position: absolute;
					margin-left: -1 * $grid-gutter-width;
				}
			}

			&:hover, &:active, &:focus {
				.info {
					transform: translateY(0);
				}
			}


		}
	}


	body.single-team {
		article.team {
			padding-top: 3 * $grid-gutter-width;
			padding-bottom: 3 * $grid-gutter-width;
		}
	}

}