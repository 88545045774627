.section.section--faqs-list {
  margin: 3rem 0;

  .faq-box {
    display: block;
    border-radius: $border-radius;
    background-color: #F6F6F6;
    height: 100%;

    .question {
      display: block;
      padding: $grid-gutter-width;
      background-color: $cpa_blue;
      color: $white;
      border-radius: $border-radius;
      span {
        min-height: 2em;
      }
    }

    .answer {
      padding: $grid-gutter-width;
      line-height: 1.8;
      color: $black;
    }
  }



}