body.blog {
    a.post-summary {
        .entry-summary {
            display: block !important;
        }
    }
}

a.post-summary {
    &.post-summary--post {
        width: 100%;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0;

        @include media-breakpoint-up(lg) {
            flex-direction: row;
            align-items: center;
        }

        .image,
        .content {
            display: inline-flex;
            flex-direction: column;
        }

        .image {
            width: 100%;
            padding-bottom: 200px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;

            @include media-breakpoint-up(lg) {
                width: 40%;
            }
        }
        .content {
            width: 100%;

            @include media-breakpoint-up(lg) {
                width: 60%;
                height: 100%;
                padding: 0 1.5rem;
            }
        }
    }
}

// News Post
body.single-post {
    header.page-header {
        padding: 2rem 0;
        justify-content: flex-start;
        background-color: rgba(0,0,0,0);
        height: auto;

        h1 {
            text-align: left;
            color: #042c6a;
            margin-bottom: 1rem;

        }
    }

    .share-btn {
        > a {
            padding: .5rem 1.5rem;
            margin: 0 .5rem;
            border-radius: 5px;
            color: $white;
            font-weight: bold;
            cursor: pointer;
            transition: all .25s ease-in-out;

            &:nth-child(1) {
                margin-left: 0;
            }

            .icon {
                padding-right: .5rem;
                color: rgba($white, .85);
            }

            &:hover {
                color: $white !important;
                opacity: .7;
            }
        }
        a[data-id='fb'] {
            background-color: #4267B2;
        }
        a[data-id='tw'] {
            background-color: #55acee;
        }
        a[data-id='in'] {
            background-color: #0077b5;
        }
        a[data-id='mail'] {
            background-color: #7d7d7d;
        }
    }
}
