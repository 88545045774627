.section.section--home-stats {
  position: relative;
  background-color: $cpa_blue;
  background-position: 0% 30%;
  background-size: cover;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $cpa_blue;
    opacity: 0.8;
    color: $white;
    content: "";
  }

  .stats-wrap {
    padding-top: 3 * $grid-gutter-width;
    padding-bottom: 3 * $grid-gutter-width;
    color: $white;
    text-align: center;
    .title {
      position: relative;
      margin-bottom: .5em;
    }


    .stat {
      display: block;
      width: 100%;
      min-height: calc(12rem + #{$grid-gutter-width * 2});

      .svgCircle {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;

      }

      .stat-circle {
        width: 12rem;
        height: 12rem;
        left: 50%;
        transform: translateX(-50%);
        margin: $grid-gutter-width 0;
        position: relative;
        .circle-thin, .circle-thick {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          position: absolute;
          top: 0;
          left: 0;
          box-sizing: content-box;
        }
        .circle-thin {
          border: .25rem solid $white;
        }
        .circle-thick {
          width: 11.5rem;
          height: 11.5rem;
          border: .75rem solid rgba(255,255,255,0.5);
        }
        .value {
          position: absolute;
          top: 50%;
          left: 0;
          width: 100%;
          transform: translateY(-50%);
          text-align: center;
          font-size: 5rem;
        }
      }

      .stat-title {
        display: block;
        width: 100%;
        text-align: center;
      }
    }
  }
}