//.slider {
//  background-color: #CCCCCC;
//  position: relative;
//  width: 100%;
//  height: 100%;
//  .slides {
//    width: 100%;
//    height: 100%;
//    position: relative;
//    .slick-list {
//      width: 100%;
//      height: 100%;
//    }
//    .slide {
//      width: 100%;
//      height: 100%;
//    }
//
//    .slick-dots {
//      position: absolute;
//      left: 50%;
//      bottom: 0;
//      transform: translateX(-50%);
//      list-style: none;
//    }
//  }
//}

.slider {
		background-color: transparent;
		position: relative;
		width: 100%;
		height: auto;
		max-height: 100%;

	&.fluid, &.slider-fluid {
		height: auto;
	}
	.slides {
		width: 100%;
		height: 100%;

		

		.slick-list {
			width: 100%;
			height: 100%;
			.slick-track {
				height: 100%;
				.slick-slide {
					width: 100%;
					height: 100%;
					>div {
						width: 100%;
						height: 100%;
					}
					.slide {
						width: 100%;
						height: 100%;

						&:before {
							content: "";
							background-color: rgba(0,0,0,0.19);
							position: absolute;
							width: 100%;
							height: 100%;
						}

						
					}
					.slide[responsive-background-image] {
						position: relative;
						img {
							display: none !important;
						}
						> a {
							position: absolute;
							top: 0;
							left: 0;
							right: 0;
							bottom: 0;
							width: 100%;
							height: 100%;
							display: block;
						}
					}
				}
			}
		}

		.slick-arrow {
			position: absolute;
			top: 50%;
			z-index: 950;
			transform: translateY(-50%);
			color: rgba(255,255,255,0.7);
			cursor: pointer;
		}
		.arrow-next, .arrow-prev {
			font-size: 2em;
			&:hover {
				color: rgba(255,255,255,0.9);
			}
		}
		.slick-prev, .arrow-prev {
			left: $grid-gutter-width-half;
			right: auto;
		}
		.slick-next, .arrow-next {
			right: $grid-gutter-width-half;
			left: auto;
		}


		.slick-dots {
			display: block;
			position: absolute;
			left: 50%;
			bottom: 0;
			width: auto;
			margin: 0 0 1em 0;
			padding: 0;
			transform: translateX(-50%);
			li {
				float: left;
				list-style-type: none;

				button {
					display: block;
					width: 11px;
					height: 11px;
					border-radius: 50%;
					overflow: hidden;
					border: 0;
					cursor: pointer;
					margin: 0 0.5em;
					background: rgba(0,0,0,0.5);
					color: rgba(0,0,0,0);
					padding: 0;
					&:focus {
						outline: 0;
					}
				}
				&.slick-active {
					button {
						background: rgba(0,0,0,1);
						cursor: default;
					}
				}
			}
		}


	}
}

.slider.ready {
	.slider-ready-hidden {
		display: none;
	}
}
.slider-ready-hidden {
	display: block;
	width: 100%;
	height: 100%;
	text-align: center;
	color: #FFFFFF;
	position: relative;
}
.slider-center {
	display: block;
	max-width: 80%;
	max-height: 80%;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	position: absolute;
}
//
//.slick-slider {
//  .slick-arrow {
//    position: absolute;
//    top: 50%;
//    color: #FFFFFF;
//    font-size: 2rem;
//    &.arrow-prev {
//      left: 0;
//    }
//    &.arrow-next {
//      right: 0;
//    }
//  }
//}




.responsive-video-wrap {
	display: none;
}






@include media-breakpoint-up(md) {
	#homepage-slider {

	}
}


/* Dots */
.slick-dotted.slick-slider
{
		margin-bottom: 30px;
}

.slick-dots
{
		position: absolute;
		top: 25%;
		display: block;
		padding: 0;
		margin: 0;
		right: -30px;
		@include media-breakpoint-up(lg) {
			right: -50px;
		}



		list-style: none;

		text-align: center;
}
.slick-dots li
{
		position: relative;

		display: block;

		width: 10px;
		height: 10px;
		margin: 10px;
		padding: 0;

		cursor: pointer;
}
.slick-dots li button
{
		font-size: 0;
		line-height: 0;
		position: relative;
		display: block;

		width: 10px;
		height: 10px;
		padding: 5px;

		cursor: pointer;

		color: transparent;
		border: 1px solid #fff;
		border-radius: 50vh;
		outline: none;
		background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
		outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
		opacity: 1;
}
.slick-dots li button:before
{
		font-family: 'slick';
		font-size: 6px;
		// line-height: 20px;

		position: absolute;
		top: 0;
		left: 0;

		// width: 20px;
		// height: 20px;

		content: 'â€¢';
		text-align: center;

		opacity: 0;
		color: #fff;

		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before
{
		opacity: .75;
		color: #fff;
		background: #fff;
		width: 8px;
		height: 8px;
		border-radius: 50vh;
		position: absolute;
		top: 1px;
		left: 1px;
		animation: fadeIn 0.5s;
}


@keyframes fadeIn {
	0% { opacity: 0; }
	25% { opacity: 0; }
	50% { opacity: 0.45; }
	75% { opacity: 0.60; }
	100% { opacity: 0.75; }
	
}


.partners-title {
	margin: 4rem auto 0 auto;
}
.partner-slider {
	margin-bottom: 0;
	background-color: #e5e5e5;

		&--container {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			padding: 0 1rem 1rem 1rem; 
			margin-bottom: 0;


			@include media-breakpoint-up(lg) {
				padding: 0 3rem 3rem 3rem;
			}
		}

		&--item {
			padding: 1rem;
		}

		&--image {
			// width: 100px;
			mix-blend-mode: multiply;
			
			&__wrapper {
				
				display: flex;
				align-items: center;
				justify-content: center;
				height: 100%;
				background-color: #e5e5e5;

			}
		}

}

.companies-list {
	width: 100%;

	.wrapper {
		padding: 0 4rem;
	}
}