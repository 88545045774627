.section.section--latest-news {
	.image-block {
		display: block;
		position: relative;
		width: 100%;
		height: 100%; 
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		// min-height: 50vh;
		//height: 100%;
		//padding-top: 100%;
		//min-height: 100%;
		&:after {
			@include media-breakpoint-up(lg) {
				content: "";
				position: absolute;
				width: 50px;
				height: 50px;
				right: -30px;
				top: 45%;
				background:$white;
				transform: rotate(45deg);
			}
		}
	}



	.posts {
		padding: $grid-gutter-width $grid-gutter-width 0 $grid-gutter-width;
		display: flex;
		flex-direction: column;
		align-items: left;
		justify-content: center;
		margin: auto;
		.news-title {
			margin-bottom: .5em;
		}

		.post {
			.entry-title {
				max-width: 100%;
			}
		}

	}
}


#grid {
	max-width: 70em;
	margin: 0 auto;
}
#list {
	width: 100%;
	height: 100% !important;
	overflow: hidden;
	margin-bottom: 30px;
}
#list .post-summary {
	width: 100%;
	margin-right: 1em;
	float: left;
	height: 200px;
	overflow: hidden;
}
#list .flex-wrapper {
	margin: auto;
	text-align: center;
}
@include media-breakpoint-up(md) {

	#list .post-summary {
		width: 47% !important;
		margin-right: 1em;
		height: 200px;
	}
}
	#list .post-summary.feature-post-1 {
		height: calc( (200px * 2) + 110px);
}

@include media-breakpoint-up(md) {

	.posts {
		padding: (2 * $grid-gutter-width) $grid-gutter-width;
	}
 

}


.feature-post-1 {
	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,0.3);
		transition: background 0.5s ease;
		z-index: 1;

	}
	&:hover {
		&:before {
			background: #042C6A;
			color: #fff;
			transition: background 0.5s ease;
		}
	}
	.flex-wrapper {
		z-index: 9;
	}
}