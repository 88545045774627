/*
    Newsletter Form
    Page Builder Block - CTA Block
*/
.newsletter-form {
    .gform_wrapper {
        background-color: rgba(0,0,0,0);
        form {
            .gform_body {
                .gform_fields {
                    display: flex;
                    flex-wrap: wrap;

                    .gfield {
                        width: 50%;
                        padding: 0 1rem;

                        label,
                        .gfield_required {
                            color: $white;
                        }
                        input[type=text] {
                            padding: .75rem 1rem !important;

                        }
                    }
                }
            }
            .gform_footer {
                display: flex;
                justify-content: center;
            }
        }
    }
}