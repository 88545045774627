//// Grid system
//.main {
//  @include make-col-ready();
//  @include media-breakpoint-up(sm) {
//    @include make-col($main-sm-columns);
//    .sidebar-primary & {
//      @include make-col($main-sm-columns - $sidebar-sm-columns);
//    }
//  }
//}
//.sidebar {
//  @include make-col-ready();
//  @include media-breakpoint-up(sm) {
//    @include make-col($sidebar-sm-columns);
//  }
//}


.container-wide {
  @include make-container();
  @include make-container-max-widths();
  @include media-breakpoint-up(xl) {
    max-width: (map_get($container-max-widths, 'xl') * 1.5);
  }
  &.no-gutters {
    @include media-breakpoint-up(lg) {
      max-width: (map_get($container-max-widths, 'lg') * 1.5) - $grid-gutter-width;
    }
    @include media-breakpoint-up(xl) {
      max-width: (map_get($container-max-widths, 'xl') * 1.5) - $grid-gutter-width;
    }
  }
}