.services-intro{
    &--block {
        & ul {
            list-style:none;
            padding: 0;
            & li {
                font-size: .875rem;
                font-weight: normal;
                line-height: 33px;
                
             
            }
        }
        
        & .item-2 {
            .row {
                display: flex;
                flex-direction: column-reverse;
            }
        }
        @include media-breakpoint-up(md){
            & .item-2 {
                .row {
                    display: flex;
                    flex-direction: row;
                }
            }
        }

        
        & .row {
            & > div {
                min-height: 70vh;
                @include media-breakpoint-up(lg) {
                    min-height: 50vh;
                } 
            }
        & .center {
            display: flex;
            flex-direction: column;
            align-content: center;

            & .content-inner{
                margin: auto;
                padding: 60px;
                z-index: 10;
                min-width: 50%;
            }
        }
        }
        & .section {
            & div[class^="col_"] {
                position: relative;
                overflow: hidden;
            }

            & .col_1 {
                &.center {
                    &:after{
                        content: "";
                        width: 50px;
                        height: 50px;
                        position: absolute;
                        top: 45%;
                        right: -10px;
                        background: #fff;
                        transform: rotate(45deg);
                        z-index: 9;
                    }
                }
            }
            & .col_2 {
                &.center  {
                    &:before {
                        content: "";
                        width: 50px;
                        height: 50px;
                        position: absolute;
                        top: 45%;
                        left: -10px;
                        background: #fff;
                        transform: rotate(45deg);
                        z-index: 9;
                    }
                }
            }
        }
    }
}

.select2-container {
    width: 100% !important;
}

.nav-select,
.select2 {
    
    @include media-breakpoint-up(lg){
        display: none;
        
    }
}
.sibling-menu {
    display: none;
    padding-left: 0;
    width: 100%;
    // display: flex;
    flex-direction:row;
    align-content: center;
    margin: auto;

    @include media-breakpoint-up(lg) {
        display: flex;
        justify-content: center;
    }

    li {
        margin: 0 15px;
        display: inline-block;  
        position: relative;
        font-size: 18px;
        text-align: center;

        a {
            cursor: pointer;
            font-weight: bold;
        }
        & .is-current {
            font-weight: bold;
        }
    }
}