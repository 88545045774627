.page-sidebar {
    border-radius: 20px;
    background-color: #042C6A;
    padding: 1.5rem;
    margin: 2rem 0;

    @include media-breakpoint-up(md) {
        margin: 0;
    }

    h1, h2, h3, h4, h5, h6, p {
        color: $white;

        a {
            font-size: inherit;
            color: $white;
            transition: all .25s ease-in-out;

            &:hover {
                opacity: .7;
            }
        }
    }
}