.section.section--home-testimonials {
	.match2 {
		min-height: 700px; 

		@include media-breakpoint-up(lg) {
			min-height: none;
		}
	}
	.companies-list {
		padding: 3rem 0.6rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.title {
			text-align: center;
			margin-bottom: 1em;
			padding: 0 1rem;
			.h2 {
			}
		}
	}


	.company {
		display: block;
		width: 45%;
		height: 180px;
		padding: 1rem;

		@include media-breakpoint-up(lg) {
			width: 45%;
			height: 180px;
			padding: 2rem;
		}

		float: left;
		position: relative;
		//padding-top: 100%;
		//border: 1px solid $cpa-border-color;
		//border-radius: .5em;
		.img {
			// position: absolute;
			// top: $grid-gutter-width * .5;
			// left: $grid-gutter-width * .5;
			// width: calc(100% - #{$grid-gutter-width});
			// height: calc(100% - #{$grid-gutter-width});
			width: 100%;
			height: 100%;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			// background-size: cover;
		}
		//.img img {
		//  width: 100%;
		//  height: auto;
		//  max-width: 100%;
		//  max-height: 100%;
		//  display: block;
		//  position: relative;
		//  top: 50%;
		//  left: 50%;
		//  transform: translate(-50%, -50%);
		//}
	}
	.companies {
		width: 100%;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		overflow: hidden;
		justify-content: center;
		align-items: center;

		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			width: 50% !important;
		}

		.wrapper {
			width: 100%;
			display: flex !important;
			flex-wrap: wrap;
			justify-content: center;
		}
	}

	.testimonial {
		min-height: 50vh;
		height: 100%;
		width: 100%;
		display: flex;
		color: $white;
		position: relative;
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0,0,0,0.5);
		}
		&:after {
			content: "";
			@include media-breakpoint-up(lg) {
				width: 50px;
				height: 50px;
				background: $white;
				position: absolute;
				left:-30px;
				top: 45%;
				transform: rotate(45deg);
			}
		}
		&-slide {
			&--item {
				height: 100%;
			}
			.slick-slide {
				// height: 65vh;
				display: flex;
				& > div {
					margin: auto;
					/*
					height: 100%;
					display: flex;
					*/
				}
			}
		}
		.overlay {
			width: 100%;
			height: 100%;
			position: absolute;
			background-color: rgba(0,0,0,0.3);
		}
		.inner-content {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			padding: $grid-gutter-width * 1;
			color: #FFF;
			justify-content: center;
			align-content: center;
			background-size: cover;
			background-position: center;

			&:before, &:after {
				position: absolute;
				font-size: 2rem;
				color: #FFF;
				font-family: "FontAwesome";
				display: none;
			}
			&:before {
				content: "\f10d";
				top: $grid-gutter-width;
				left: $grid-gutter-width;
				z-index:999;
			}
			&:after {
				content: "\f10e";
				bottom: $grid-gutter-width;
				right: $grid-gutter-width;
				z-index:999;
			}


			.testimonial-title {
				font-family: $headings-font-family;
				font-weight: $headings-font-weight;
				text-align: center;
				font-size: 2.3rem;
				// margin-top: $grid-gutter-width * 3;
				z-index: 999;
			}
			.testimonial-slide {
				z-index: 999;
			}
			blockquote {
				text-align: center;
				font-size: font-size(2);
				line-height: 1.8;
				margin-top: 0;
				margin-bottom: 0;
				font-style: italic;
			}
			cite {
				display: block;
				text-align: center;
				font-weight: bold;
				font-style: normal;
				font-size: 2rem;
				&:before {
					content: "-";
					margin-right: .5em;
				}
			}
		}
	}
}

@include media-breakpoint-up(md) {
	.section.section--home-testimonials {

		.companies-list {
			padding: 3em 1em;
			@include media-breakpoint-up(sm) {
				padding: 8em 1em;
			}
		}

		.testimonial {
			min-height: unset;
			.inner-content {
				padding: $grid-gutter-width * 2;
				height: 100%;
				&:before, &:after {
					font-size: 5rem;
					display: inline-block;
				}
			}

			&-slide {
				&--item {
					height: unset;
				}
			}
			.slick-slide {
				height: unset;
			}

			blockquote {
				font-size: font-size(3) !important;
				margin-top: $grid-gutter-width;
				margin-bottom: $grid-gutter-width;
			}
		}
	}
}


