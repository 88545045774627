.section {
    &--contact {
        .container {
            & > div {
                margin: 0 auto;
                
            }

            .gform_wrapper {
                .gfield_label {
                    padding-left: 4px;
                    margin-bottom: 0;
                }
            }
        }
    }
}