html {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}


[responsive-background-image], [data-responsive-background-image] {
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	img {
		display: none;
	}
}

/** SPINNER **/
.spinner {
	margin: 100px auto 0;
	width: 70px;
	text-align: center;
}

.spinner > div {
	width: 18px;
	height: 18px;
	background-color: #333;

	border-radius: 100%;
	display: inline-block;
	-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
	animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}

.spinner .bounce2 {
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
	0%, 80%, 100% { -webkit-transform: scale(0) }
	40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
	0%, 80%, 100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	} 40% {
			-webkit-transform: scale(1.0);
			transform: scale(1.0);
		}
}

pre.pre {
	background: #FFF;
	color: #000;
	font-size: 10px;
	line-height: 1.2;
	padding: 10px;
	max-height: 50vh;
	overflow: scroll;
}



.section.section--content {
	.col {
		img {
			max-width: 100%;
		}
		.content-inner {
			padding: $grid-gutter-width;
			> * {
				max-width: 80%;
			}
		}
	}
}

.section.section--content.cols-2.layout-left .col_2, .section.section--content.cols-2.layout-right .col_1 {
	.content-inner {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		justify-content: center;
		align-items: center;
		padding-left: 16.667%;
		padding-right: 16.667%;
		h1, h2, h3, h4, h5, h6 {
			&:first-child {
				margin-bottom: 1em;
			}
		}
	}
}

.section .image-box {
	display: block;
	width: 100%;
	//height: 100%;
	background-size: cover;
    background-repeat: no-repeat;
	min-height: 100%;
	>img {
		display: none;
	}
}

ul.links-list {
	list-style: none;
	padding-left: $grid-gutter-width;
	li {
		&:before {
			content: "\00BB";
			display: inline-block;
			margin-left: -1 * $grid-gutter-width;
			width: 1 * $grid-gutter-width;
		}
		margin-bottom: 1em;
	}
}


.container, .container-fluid {
	&.no-gutters {
		padding-left: 0;
		padding-right: 0;
	}
}


.entry-content {
	>img, >p >img {
		max-width: 100%;
		height: auto;
	}
}

.entry--container {
	margin-top: 4em;
}




// Typography
h1, .h1 {
	font-size: font-size(6);
}
h2, .h2 {
	font-size: 2rem;
	line-height: 1.5;
}
h3, .h3 {
	font-size: font-size(4);
}
h4, .h4 {
	font-size: font-size(3);
}
h5, h5 {
	font-size: font-size(2);
}
h6, .h6 {
	font-size: font-size(1);
}




.main-content {

	> * {
		max-width: 100%;
	}
}

.post {
	* > {
		max-width: 100%;
	}
}


//helpers 

.flex {
	display: flex;
}
@include media-breakpoint-up(md) {
	.pad40 {
		padding: 4rem;
	}
}

.main-content--padding-override {
	padding: 80px;

	p {
		width: 100%;
	}
}


//ubermenu 
.ubermenu-submenu {

	.ubermenu-target:hover {
		cursor: pointer;
		.ubermenu-target-title {
			text-decoration: underline !important;
		}
	}
	
}
.ubermenu-item-level-2  {
	a {
		font-size: 14px;
		line-height: 1.6 !important;
	}
	div {
		font-size: 14px;
	}
}
.ubermenu-submenu-id-784 {
	position: relative;
	margin-top: 20px !important;
	overflow: visible !important;
	&:before {
		//content: "";
		//width: 30px;
		//height: 30px;
		//background: black;
		//top: -10px;
		//left: 40px;
		//position: absolute;
		//transform: rotate(45deg);

	}
	
	.ubermenu-item { 
			//width: 33.33% !important;
			width: 100% !important;

	}

	@include media-breakpoint-up(md) {
		& > .ubermenu-item {
			 width: 33.33% !important;
		}
	}
}

@include media-breakpoint-up(md) {

	.ubermenu-submenu-id-784 {
		& > .ubermenu-item {
			border-right: 1px solid;
			min-height: 304px;
			&.ubermenu-column-id-378 {
				border-right: none;
			}
		}
	}

	.ubermenu .ubermenu-item-784.ubermenu-item-level-0 {
		
		& > .ubermenu-target {
			
			position: relative;
			
			&:before {
				content: "";
				width: 30px;
				height: 30px;
				background: #f6f6f6;
				position: absolute;
				bottom: -40px;
				left: 45%;
				opacity: 0;
				transform:  rotate(45deg);
				z-index: -1;
			}
		}	
		
		&.ubermenu-active > .ubermenu-target {
			
			&:before {
				opacity: 1;
				transition-property: opacity;
				transition-duration: .5s;
				transition-timing-function: ease-in;
			}
		}
	}

	.ubermenu-custom-content-padded {
		padding-bottom: 0 !important;
	}
	.ubermenu-item-380 {
		.ubermenu-custom-content-padded {
			padding-top: 0 !important;
		}
	}

}
#list {
	margin: 4em 0;
}

.body-content {
	@include media-breakpoint-up(lg){
		max-width: 80%;
	}
}

// Formats Styles
.intro-content-text {
	@extend .h2;
	line-height: 120% !important;
	color: $brand-primary;
	margin-bottom: 1.5rem !important;

	@include media-breakpoint-up(lg) {
		font-size: 1.5rem;
	}
}
