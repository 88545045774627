// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

.gform_wrapper {
  background-color: $gray-light;
  padding: $grid-gutter-width;
  border-radius: $border-radius;

  input[type="text"],
  input[type="password"],
  input[type="number"],
  input[type="search"],
  textarea {
    background-color: $white;
    border: 0;
    border-radius: .3rem;
  }

  input[type="checkbox"] {
    border: 0;
  }

  &.gf_browser_chrome {
    .gfield_checkbox li input,
    .gfield_checkbox li input[type="checkbox"] {
      margin-top: 0px !important;
    }
  }

  .gform_title {
    text-align: center;
    font-size: font-size(5);
  }

  .gform_footer {
    overflow: hidden;
    clear: both;
    &:after {
      content: "";
      clear: both;
      display: table;
    }
  }

  input[type="submit"] {
    display: inline-block;
    background-color: $cpa_red;
    color: $white;
    font-weight: bold;
    float: right;
    border-radius: 0.3rem;
    border: 0;
    padding: .5em 2em;
    &:after {
      content: "";
      clear: both;
      display: table;
    }
  }
}

.gform_confirmation_message {
  display: block;
  width: 100%;
  text-align: center;
  padding: 30px;
  background-color: #FFEEEE;
  border-radius: .3rem;
  border: 2px solid #FF0000;
}

.ginput_container {
    .gfield_checkbox {
      label {
        font-size: 14px !important;
        margin-left: 10px !important ;
      }
    }
    .gfield_radio {
      input {
        margin: 7px 5px 5px 5px  !important;
      }
      label {
        font-size: 14px !important;
        margin-left: 10px !important;
        vertical-align: top !important;
        line-height: 24px !important;
      }
    }
}