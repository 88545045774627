footer.page-footer {
  background-color: $cpa_blue;
  padding-top: 2 * $grid-gutter-width;
  padding-bottom: 2 * $grid-gutter-width;
  color: $white;
  text-align: center;



  .social-links {
    font-size: 1.2em;
    .social-link {
      color: $white;
    }
    .social-link:not(:last-child) {
      margin-right: 10px;
    }
  }

  .footer-links {

  }
  .footer-link {
    display: block;
    color: #FFF;
    line-height: 1.8;
    &:hover {
      color: #FFF;
      text-decoration: underline;
    }
  }

  .footer-columns {
    .col-12 {
      margin-bottom: $grid-gutter-width;
    }
  }

  .footer-shield {
    display: block;
    width: 100%;
    position: relative;
    min-width: 100px;
    min-height: 100px;
    img {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -50px;
      margin-top: -50px;
      width: 100px;
      height: auto;
    }
  }
}

$mc_border_radius: .2rem;
#mc_embed_signup {

  .mc-field-group {
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 0.5em;

    input {
      flex: 1;
      background-color: #FFF;
      border-radius: $mc_border_radius;
      padding: ($grid-gutter-width * 0.25) ($grid-gutter-width * .5);
      color: $black;
      font-family: $font-family-base;
      font-size: 1rem;
      line-height: 1;
      border: 0;
      &:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    input[type=submit] {
      background-color: #FF0000;
      color: $white;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      flex: 0 ;
    }
  }

}

@include media-breakpoint-up(md) {
  footer.page-footer {
    text-align: left;
  }
}