.btn-red,
.btn-secondary {
	background-color: $cpa_red;
	border-color: $cpa_red;
	color: #fff;
	&.content-button {
		padding: .875rem 1.5rem;
		font-weight: bold;
	}
	&:hover {
		border-color: $cpa_red;
		background-color: lighten($cpa_red, 4%);
		color: #fff;
		
	}
}

.ubermenu-item-level-0 > .ubermenu-target {
	padding: 27px 23px 13px 10px !important; 
}
.ubermenu.ubermenu-skin-minimal .ubermenu-item-level-0>.ubermenu-target {
	font-size: 16px !important;
}

.content,
.entry-content {

	ul {
		list-style-type: none;
	}

	li {

		padding-left: 1.3em;

	}

	li:before {

		content: "\f054"; /* Icon Unicode */
		font-family: FontAwesome; /* Icon Family */
		display: inline-block;
		margin-left: -1.3em; /* same as padding-left set on li */
		width: 1.3em; /* same as padding-left set on li */
	
	}
}