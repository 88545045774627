//@import '../../bower_components/bootstrap/scss/variables';


// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;
$grid-gutter-width-base: 30px;
$grid-gutter-width-half: $grid-gutter-width-base * 0.5;

// Typography
$font-scale: (
        1: 0.875rem,
        2: 1rem,
        3: 1.25rem,
        4: 1.5rem,
        5: 2.25rem,
        6: 3rem,
        7: 5rem,
        8: 6rem
) !default;


// CPA Vars
$cpa_blue: #042C6A;
$cpa_red: #D0021B;



$black:             #000;
$white:             #fff;
$light-gray:        #ebebeb;
$gray:              #eaedf2;
$text-color:        #000000;
$meta-gray:         #9fa6b4;
$gray-dark:         #464646;
$gray-light:        #EBEBEB;

$cpa-border-color:  #E6E6E6;


// Bootstrap Defaults Override
$brand-primary:     $cpa_blue;
$link-color:        $cpa_blue;
$link-hover-color:  lighten($cpa_blue, 4%);


$font-family-sans-serif: "PT Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
$headings-font-family: "PT Sans", Helvetica, Arial, sans-serif;
$headings-font-weight:  700;
$body-bg:           $white;
$body-color:        $black;


$border-radius: 0.3rem;

$enable-flex:true;


// IE 11 flexbox fix.
// TODO: Remove when fixed in bootstrap.
// https://github.com/twbs/bootstrap/issues/17310
