.yarpp-related {
  margin-bottom: $grid-gutter-width;
  .related-posts {

    .related-title {
      margin-bottom: $grid-gutter-width;
      display: inline-block;
    }

  }

}


