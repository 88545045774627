.page.testimonials {

	.testimonial-block {
		width: 100%;
		height: auto;
		margin-bottom: $grid-gutter-width;
		padding: $grid-gutter-width * .5;
		border-radius: $border-radius;
		border: 2px solid $gray-200;
		.img {
			width: 100%;
			height: auto;
			margin-bottom: $grid-gutter-width * .5;

			img {
				width: auto;
				height: 5rem;
			}
		}
		hr {
			color: $gray-400;
		}
		
		.author {
			font-weight: bold;
		}
		.position {
			color: #4A4A4A;
		}
	}

}

@include media-breakpoint-up(md) {
	.page.testimonials {
		.testimonial-block {
			width: 100%;
			//height: calc(100% - #{$grid-gutter-width});
		}
	}
}