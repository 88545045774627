.section.section--home-toggle {

  .tab-selectors {
    //background-color: $cpa_blue;
    label {
      margin-bottom: 0;
    }
    .toggle-box {
      display: block;
      width: 100%;
      height: 0;
      padding-top: 30%;
      @include media-breakpoint-up(lg) {
        padding-top: 80%;
      }
      background-color: $cpa_blue;
      color: $white;
      transition: all 0.2s ease-in;
      position: relative;
      cursor: pointer;
      &:hover {
        background-color: $white;
        //color: $cpa_blue;
        text-decoration: none;
        .inner-content {
          background-color: rgba(0,0,0,0);
          background-size: cover;
        }
      }
        &.active {
          &:after {
            @include media-breakpoint-up(lg){

              content: "";
              position: absolute;
              bottom: -25px;
              left: 50%;
              width: 50px;
              height: 50px;
              background: #f6f6f6;
              transform: rotate(45deg);
            }
          }
          .toggle-overlay {
            background: #fff;
          }
        }
      .inner-content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        background-size: cover;
        background-color: rgba(0,0,0,0.5);
        transition: background-color 0.4s ease-in-out;
        .toggle-title {
          font-weight: bold;
        }
      }
      .toggle-overlay {
        width: 100%;
        height: 100%;
        background: #000;
        opacity: .25;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

  }

  .tabs {
    .container-wide .wrap {
      display: block;
      width: 100%;
      //height: 100%;
      position: relative;
      background-color: #F6F6F6;
      .container {
        overflow: hidden;
        //height: 100%;
        >.inner {
          width: 100%;
          min-height: 60vh;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          position: relative;
        }
      }
    }
  }

  @keyframes slideIn {
    0% {
      opacity: 0;
      transform: translate(100%);
    }
    100% {
      opacity: 1;
      transform: translate(0%);
    }
  }
  @keyframes slideOut {
    0% {
      opacity: 1;
      transform: translate(0%);
    }
    100% {
      opacity: 0;
      transform: translate(100%);
    }
  }
    .tab {
      padding-top: 2 * $grid-gutter-width;
      padding-bottom: 2 * $grid-gutter-width;
      display: inline-block;
      position: absolute;
      width:100%;
      top: 0;
      
      &.services {
        transform: translate(100%);
        opacity: 0;
        left: 100%;
        
        &.active {
          transform: translate(0%);
          animation: slideIn 0.5s;
          opacity: 1;
          left: 0;
        }
      }
      &.training {
        transform: translate(100%);
        opacity: 0;
        left: 100%;
        &.active {
          transform: translate(0%);
          animation: slideIn 0.5s;
          opacity: 1;
          left: 0;
        }
      }
      &.consulting {
        transform: translate(100%);
        opacity: 0;
        left: 100%;
          &.active {
            transform: translate(0%);
            animation: slideIn 0.5s;
            opacity: 1;
            left: 0;
          }
      }

      .inner-content {
        // padding-top: 2 * $grid-gutter-width;
        padding-bottom: 2 * $grid-gutter-width;
        .tab-title {
          display: block;
          text-align: center;
          margin-bottom: 0.5em;
        }
      }
      .items {
        .item {
          background-color: $white;
          color: $cpa_blue;
          border-radius: .5rem;
          border: 1px solid #E6E6E6;
          width: 100%;
          height: 100%;
          display: block;
          display: flex;
          transition: all 0.2s ease-in;

          &:hover {
            background-color: $cpa_blue;
            color: $white;
            text-decoration: none;
          }
          .inner-content {
            position: relative;
            padding: $grid-gutter-width 0;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 0 auto;
          }
          img {
            max-width: 25%;
            display: block;
            margin: 0 auto;
            margin-bottom: 0.5em;
          }
          .icon {
            display: block;
            margin: 0 auto 1em auto;
            width: 135px;
            height: 141px;
            background-size: contain;
            background-repeat: no-repeat;
            
            img {
              max-width: 50%;

            }
          }
          .title {
            font-weight: bold;
            padding: 0 0.5em;
            line-height: 1.2;
            min-height: 2.4em;
            margin: 0;
          }
        }
      }
    }

  }

